import { Component, Input } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import { ICourseDetails } from 'modules/course/common/models/course.model';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { ICollectionCourseItem } from 'modules/course/common/models/learning-object-structure.model';
import { Player } from 'modules/course/player/services/player.service';

@Component({
  selector: 'course-player-menu',
  templateUrl: './course-player-menu.component.html',
})
export class CoursePlayerMenuComponent {
  @Input() player: Player;
  @Input() menuEnabled: boolean;

  menuItems: {
    upNext: boolean;
    collectionPlaylist: boolean;
    relatedCollections: boolean;
  };

  private _registration: ILearningObjectRegistration;
  private _collection: ICollectionCourseItem | ICourseDetails;

  constructor(public globalConfig: GlobalConfig) {}

  @Input() set registration(registration: ILearningObjectRegistration) {
    this._registration = registration;
    this.setupMenuItems();
  }

  get registration(): ILearningObjectRegistration {
    return this._registration;
  }

  @Input() set collection(collection: ICollectionCourseItem | ICourseDetails) {
    this._collection = collection;
    this.setupMenuItems();
  }

  get collection(): ICollectionCourseItem | ICourseDetails {
    return this._collection;
  }

  setupMenuItems() {
    const collectionId = this.collection?.id;

    this.menuItems = {
      upNext: this.globalConfig.settings.coursePlayer?.upNext && !!collectionId,
      collectionPlaylist: this.globalConfig.settings.coursePlayer?.collectionPlaylist && !!collectionId,
      relatedCollections:
        this.globalConfig.settings.coursePlayer?.relatedCollections && !collectionId && !!this.registration,
    };
  }

  menuItemsBound(count: number) {
    if (count) {
      this.menuEnabled = true;
    }
  }
}
