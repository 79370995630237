import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import _ from 'lodash';
import { IPurchaseResultDetails } from '../models/checkout.models';

@Component({
  selector: 'checkout-confirmation',
  templateUrl: './checkout-confirmation.component.html',
})
export class CheckoutConfirmationComponent implements OnInit {
  @Input() purchase: IPurchaseResultDetails;

  readonly currentUser = this.currentUserService.get();

  constructor(
    private stateService: StateService,
    private currentUserService: CurrentUserService,
  ) {}

  ngOnInit(): void {
    this.purchase.cart = _.map(this.purchase.cart || [], (cartItem) => {
      cartItem.id = cartItem.productId;

      return cartItem;
    });
  }

  printReceipt(): void {
    window.print();
  }

  readonly isStateAvailable = (state: string) => !!this.stateService.get(state);
}
