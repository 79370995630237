import { Directive, HostListener } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import _ from 'lodash';

@Directive({
  selector: '[anchorScroll]',
})
export class AnchorScrollDirective {
  constructor(
    private router: UIRouterGlobals,
    private window: Window,
  ) {}

  @HostListener('click', ['$event'])
  handleClick(event: Event): void {
    const target = event.target as HTMLAnchorElement;

    if (target && target.tagName === 'A') {
      const href = target.getAttribute('href');
      const targetId = href.split('#')[1];
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        event.preventDefault();
        targetElement.scrollIntoView({ behavior: 'smooth' });

        if (targetElement.offsetTop && _.get(this.router, 'current.data.offset')) {
          //then move down window by size of offset of elem
          this.window.scrollTo(0, targetElement.offsetTop - _.get(this.router, 'current.data.offset'));
        }
      }
    }
  }
}
