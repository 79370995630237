import { Component, OnInit } from '@angular/core';
import { ISiteSettings } from 'core/site-settings/models/site-settings.model';
import { SiteSettingsService } from 'core/site-settings/services/site-settings.service';

@Component({
  selector: 'app-open-id-connect-error',
  templateUrl: './open-id-connect-error.component.html',
})
export class OpenIdConnectErrorComponent implements OnInit {
  siteSetting: ISiteSettings;

  constructor(private siteSettingsService: SiteSettingsService) {}

  async ngOnInit() {
    this.siteSetting = await this.siteSettingsService.getCurrent();
  }
}
