import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ICourseRegistration } from 'modules/course-registrations/models/course-registration.model';
import { ICourse } from 'modules/course/common/models/course.model';

@Directive({
  selector: 'registration-menu',
})
export class RegistrationMenuDirective extends UpgradeComponent {
  static readonly selector = 'registrationMenu';

  @Input() course: Partial<ICourse>;
  @Input() registration?: ICourseRegistration;
  @Input() showDetails?: boolean;
  @Input() showHistory?: boolean;
  @Input() showRemoveAction?: boolean;
  @Input() showTargetActions?: boolean;
  @Input() alignMenu?: 'right' | 'left';
  @Input() mode?: 'link' | 'ellipsis';

  constructor(elementRef: ElementRef, injector: Injector) {
    super(RegistrationMenuDirective.selector, elementRef, injector);
  }
}
