import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalConfig } from 'core/environment';
import _ from 'lodash';
import { courseRegistrationStatuses } from 'modules/course-registrations/models/course-registration-status.model';
import { TrainingService } from 'modules/course-registrations/services/training.service.ajs-upgraded-provider';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { Subject, Unsubscribable, interval } from 'rxjs';

@Component({
  selector: 'external-content-view',
  templateUrl: './external-content-view.component.html',
})
export class ExternalContentViewComponent implements OnInit, OnDestroy {
  @Input() registration: ILearningObjectRegistration;
  @Output() finished = new EventEmitter<void>();
  @Input() wrapped: string;
  @Input() playerActions: Subject<string>;
  @Input() closeSubject: Subject<void>;
  @Output() closed = new EventEmitter<void>();
  @Output() launch = new EventEmitter<string>();

  embed = true;
  aboutBlankUrl = 'about:blank';
  forceNewWindow = false;
  _playerPath: SafeResourceUrl;

  private _type: string;
  private registrationRefresherSubscriber?: Unsubscribable;
  private refreshPromise?: Promise<void>;

  constructor(
    private trainingService: TrainingService,
    private domSanitizer: DomSanitizer,
    private window: Window,
    private globalConfig: GlobalConfig,
  ) {}

  @Input() set type(type: string) {
    this.playerPath = this.trainingService.buildExternalLaunchPath(
      this.registration.courseId,
      this.registration.id,
      type,
    );
    this._type = type;
  }

  get type(): string {
    return this._type;
  }

  set playerPath(path: string) {
    this._playerPath = this.domSanitizer.bypassSecurityTrustResourceUrl(path);
  }

  get playerPath(): SafeResourceUrl {
    return this._playerPath;
  }

  ngOnInit() {
    if (!this.playerPath) {
      this.playerPath = this.aboutBlankUrl;
    }

    this.forceNewWindow = _.includes(
      this.globalConfig.settings.courseDetails.forceNewWindowFormats || [],
      this.registration.course.format,
    );

    if (
      ['course', 'download', 'launch'].includes(this.type) &&
      (this.registration.course.format === 101 ||
        this.registration.course.format === 103 ||
        (this.registration.course.scorm && this.forceNewWindow))
    ) {
      this.embed = false;
    }

    //This approach required because an old Assessment/Evaluation/Course players will be used
    //and they can't send any events to this code.
    this.registrationRefresherSubscriber = interval(3e3).subscribe(() => {
      if (!this.refreshPromise) {
        this.refreshPromise = this.registration
          .checkStatusActual()
          .then((response) => {
            if (!response.actual) {
              this.registration.reload();
            }
          })
          .finally(() => {
            delete this.refreshPromise;
          });
      }
    });

    this.closeSubject.subscribe(() => {
      this.playerPath = this.aboutBlankUrl;
    });
  }

  ngOnDestroy() {
    this.registrationRefresherSubscriber?.unsubscribe();
    delete this.registrationRefresherSubscriber;
  }

  popupExternalPlayer(action: string) {
    const wnd = this.window.open(this.sanitaze(this.playerPath));

    this.trainingService.pollCoursePlayerWindow(wnd).then(() => {
      this.registration.reload().then(() => {
        if (this.registration.actions.includes(action) && this.registration.status_id === 4) {
          //We need launch step to allow execute popupExternalPlayer next time
          this.launch.next(action);
        }

        this.finished.emit();
      });
    });
  }

  hasAction(action: string): boolean {
    return this.registration.actions.includes(action);
  }

  isCompleted(): boolean {
    return courseRegistrationStatuses.strictCompletedSet.includes(this.registration.status_id);
  }

  private sanitaze(url: SafeResourceUrl): string {
    return this.domSanitizer.sanitize(SecurityContext.URL, url);
  }
}
