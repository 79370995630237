import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { GlobalConfig } from 'core/environment';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { MetaService } from 'core/services/meta.service.ajs-upgraded-provider';
import _ from 'lodash';
import { IDiscussion } from 'modules/discussions/models/discussion.model';
import { ActivityOrigin, IUserNetworkActivity } from 'modules/network/models/user-network-activity.model';
import { NetworkService, NetworkServiceResponse } from 'modules/network/services/network.service';
import { UserNetworkActivityService } from 'modules/network/services/user-network-activity.service';
import { Unsubscribable, finalize } from 'rxjs';

@Component({
  selector: 'discussion-topic',
  templateUrl: './discussion.component.html',
  providers: [NetworkService],
})
export class DiscussionComponent implements OnInit, OnDestroy {
  @Input() discussionTopic?: IDiscussion;
  @Input() trackingName = '';

  isEditable = false;
  loading = false;
  searchRequestPromise?: Unsubscribable;

  itemCount = 0;
  activities: IUserNetworkActivity[] = [];
  searchQuery = '';

  searchRequest?: NetworkServiceResponse<IUserNetworkActivity>;
  public readonly activityOrigin: ActivityOrigin = 'discussions';

  constructor(
    private backUrlService: BackUrlService,
    private userNetworkActivityService: UserNetworkActivityService,
    private globalConfig: GlobalConfig,
    private networkService: NetworkService<IUserNetworkActivity>,
    private metaService: MetaService,
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.isEditable =
      this.globalConfig.settings.view === 'admin' &&
      !!_.find(this.discussionTopic.permissions, function (p) {
        return p.toLowerCase() === 'discussion.edit';
      });

    this.metaService.setMeta({
      title: this.discussionTopic.title,
      description: this.discussionTopic.description,
      url: this.stateService.href('main.discussion', { id: this.discussionTopic.id }),
      type: 'website',
    });

    this.networkService.init(this.userNetworkActivityService);
    this.search();
  }

  ngOnDestroy() {
    if (this.searchRequest) {
      delete this.searchRequest;
    }

    if (this.searchRequestPromise) {
      this.searchRequestPromise.unsubscribe();
      delete this.searchRequestPromise;
    }
  }

  search() {
    if (this.searchRequest) {
      delete this.searchRequest;
    }

    this.searchRequest = this.networkService.createSearchRequest({
      topicId: this.discussionTopic.id,
      query: this.searchQuery ? this.searchQuery : undefined,
      activityOrigin: this.activityOrigin,
    });

    this.loading = true;
    this.searchRequestPromise = this.searchRequest
      .load()
      .pipe(
        finalize(() => {
          this.searchRequestPromise.unsubscribe();
          delete this.searchRequestPromise;
          this.loading = false;
        }),
      )
      .subscribe((response) => {
        this.itemCount = response.count;
        this.activities = response.items;
      });
  }

  goBack() {
    this.backUrlService.goBack();
  }

  prepareNewPost() {
    this.networkService
      .showEditThreadDialog(
        this.userNetworkActivityService.newPost({
          topicId: this.discussionTopic.id,
          activityOrigin: this.activityOrigin,
        }),
      )
      .then((post) => {
        this.itemCount += 1;
        this.searchRequest.offset += 1;
        this.activities.unshift(post);
      });
  }

  loadMore() {
    if (this.searchRequest && !this.loading) {
      this.loading = true;
      this.searchRequestPromise = this.searchRequest
        .loadMore()
        .pipe(
          finalize(() => {
            this.searchRequestPromise?.unsubscribe();
            delete this.searchRequestPromise;
            this.loading = false;
          }),
        )
        .subscribe((result) => {
          if (result) {
            this.activities.push(...result.items);
          }
        });
    }
  }
}
