import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import { LearningObjectRegistrationWorkflowService } from 'modules/course-registrations/services/learning-object-registration-workflow.service.ajs-upgraded-provider';
import { ILaunchData } from 'modules/course/common/models/learning-object-registration.model';
import { EMPTY, Subject, catchError } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Component({
  selector: 'internal-content-view',
  templateUrl: './internal-content-view.component.html',
})
export class InternalContentViewComponent implements OnInit {
  @Input() workflow: LearningObjectRegistrationWorkflowService;
  @Input() type: string;
  @Output() finished = new EventEmitter<void>();
  @Input() wrapped: string;
  @Input() playerActions: any;
  @Output() closed = new EventEmitter<void>();
  @Input() closeSubject: Subject<void>;

  @Input() quizRetake: Subject<{ resultId: number; skipStartStep?: boolean }>;
  @Output() retake = new EventEmitter<string>();

  launchData?: ILaunchData;

  settings = this.globalConfig.settings;

  constructor(private globalConfig: GlobalConfig) {}

  ngOnInit() {
    fromPromise(this.workflow.registration.getLaunchData(this.type))
      .pipe(
        catchError(() => {
          return EMPTY;
        }),
      )
      .subscribe((data) => this.handleLaunchData(data));
  }

  handleRetake(quizType: string) {
    this.retake.emit(quizType);
  }

  finishHandler() {
    this.workflow.registration.reload().then(() => {
      this.finished.emit();
    });
  }

  onPlayerClose() {
    this.closed.emit();
  }

  private handleLaunchData(data: ILaunchData) {
    this.launchData = data;
  }
}
