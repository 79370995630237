import { Component, Input, OnInit } from '@angular/core';
import { UrlVersionPipe } from 'components/pipes';
import { GlobalConfig } from 'core/environment';

@Component({
  selector: 'course-thumbnail',
  templateUrl: './course-thumbnail.component.html',
})
export class CourseThumbnailComponent implements OnInit {
  static readonly selector = 'courseThumbnail';

  @Input() course: { id: string | number };
  @Input() size: number;
  @Input() disableDefaultIcon: boolean;
  @Input() skipDefault?: boolean = this.globalConfig.settings.courseThumbnailSettings?.skipDefault || false;

  showDefaultIcon: boolean;
  backgroundImageUrl: string;
  setValue: number;
  globeImage: boolean;
  thumbnailUrl: string;

  private _version: number;

  constructor(
    private globalConfig: GlobalConfig,
    private urlVersionPipe: UrlVersionPipe,
  ) {}

  ngOnInit() {
    if (this.globalConfig.settings.courseThumbnailSettings?.randomBackgroundColor) {
      this.setValue = parseInt(this.course.id.toString(), 10) % 3;
    }

    this.loadCourseImage();
  }

  @Input()
  set version(value: number) {
    this._version = value;

    this.loadCourseImage();
  }

  private loadCourseImage() {
    const img = new Image();
    const thumbnailUrl = this.urlVersionPipe.transform(
      `/a/course/${this.course.id}/thumbnail/?skip_default=${this.skipDefault.toString()}`,
    );

    img.onload = () => {
      if (img.width > 1) {
        this.showDefaultIcon = false;
        this.backgroundImageUrl = ['url(', thumbnailUrl, ')'].join('');
        this.globeImage = !this.skipDefault;
        this.thumbnailUrl = thumbnailUrl;
      } else {
        this.showDefaultIcon = true;
        this.backgroundImageUrl = null;
      }

      img.onload = null;
      img.remove();
    };

    img.src = thumbnailUrl;
  }
}
