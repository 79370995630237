import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { CourseRegistrationUnionType } from 'modules/course-registrations/models/course-registration.model';
import { CourseRegistrationActionService } from 'modules/course-registrations/services/cource-registration-action.service';
import { Unsubscribable, finalize, tap } from 'rxjs';

@Component({
  selector: 'pay-later',
  templateUrl: './pay-later.component.html',
})
export class PayLaterComponent implements OnInit, OnDestroy {
  @Input() sessionId: number;
  @Input() courseId: number;
  @Input() registration: CourseRegistrationUnionType;

  paymentNotesModel: string;
  registerSubscription?: Unsubscribable;

  constructor(
    private courseRegistrationActionService: CourseRegistrationActionService,
    private backUrlService: BackUrlService,
    private notificationService: NotificationService,
    private stateService: StateService,
  ) {}

  ngOnInit(): void {
    this.paymentNotesModel = this.registration.paymentNotes || '';
  }

  ngOnDestroy(): void {
    this.registerSubscription?.unsubscribe();
  }

  goBack(): void {
    this.backUrlService.goBack();
  }

  register(form: FormGroup): void {
    if (form.valid) {
      this.registerSubscription = this.courseRegistrationActionService
        .execute(this.courseId, {
          action: 'PurchaseCourseLaterAction',
          data: {
            paymentNotes: this.paymentNotesModel,
            session_id: this.sessionId,
          },
        })
        .pipe(
          tap((registration) => {
            if (registration?.notification?.validation?.message) {
              this.notificationService.error(registration.notification.validation.message, 5e3);
              this.registerSubscription?.unsubscribe();
              this.registerSubscription = null;
            } else {
              this.stateService.go('main.course', { id: this.courseId });
            }
          }),
          finalize(() => {
            this.registerSubscription?.unsubscribe();
            this.registerSubscription = null;
          }),
        )
        .subscribe();
    }
  }
}
