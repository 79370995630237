import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { MarkdownRenderService } from 'core/services';
import _ from 'lodash';
import { IHelpText } from 'modules/help-texts/models/help-text.model';
import { HelpTextService } from 'modules/help-texts/services/help-text.service';

@Component({
  selector: 'help-text',
  templateUrl: './help-text.component.html',
})
export class HelpTextComponent implements OnInit {
  loading = false;
  helpText?: IHelpText;

  constructor(
    private helpTextService: HelpTextService,
    public backUrlService: BackUrlService,
    private markdownService: MarkdownRenderService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.helpTextService.get().subscribe((helpText) => {
      this.helpText = helpText;
      this.loading = false;
    });
  }

  _getHtmlSafe(key: string): SafeHtml {
    /*
    Here we decide to turn off the sanitizer because we want to use the feature of having the anchored
    elements rendered by markdown to be able to navigate to a specific place on a page using the anchor link.
    More information could be found here
    https://yt.kmionline.com/issue/TRAIN3-21964/Upgrade-from-AngularJS-to-Angular-sanitize-dynamic-content-Markdown
    */
    return this.sanitizer.bypassSecurityTrustHtml(this.markdownService.render(_.get(this.helpText, key, '')));
  }

  getHelpName(): SafeHtml {
    return this._getHtmlSafe('name');
  }

  getHelpText(): SafeHtml {
    return this._getHtmlSafe('text');
  }
}
