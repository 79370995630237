import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IInstructor } from 'modules/instructors/models/instructor.model';

@Component({
  selector: 'instructor-profile-modal',
  templateUrl: './instructor-profile-modal.component.html',
})
export class InstructorProfileModalComponent {
  @Input() instructor: IInstructor;

  constructor(private activeModal: NgbActiveModal) {}

  cancel() {
    this.activeModal.dismiss();
  }
}
