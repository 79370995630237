import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { GlobalConfig } from 'core/environment/global-config.service.ajs-upgraded-provider';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { SecurityService } from 'core/services';
import { MetaService } from 'core/services/meta.service.ajs-upgraded-provider';
import { IBlogPost } from 'modules/blog/models/blog-post.model';

@Component({
  selector: 'blog-post-details',
  templateUrl: './blog-post-details.component.html',
})
export class BlogPostDetailsComponent implements OnInit {
  @Input() post: IBlogPost;

  isEditable: boolean;
  readonly isStateAvailable = this.securityService.isStateAvailable;
  constructor(
    private backUrlService: BackUrlService,
    private stateService: StateService,
    private metaService: MetaService,
    private globalConfig: GlobalConfig,
    private securityService: SecurityService,
  ) {}

  ngOnInit(): void {
    this.isEditable =
      this.globalConfig.settings.view === 'admin' &&
      !!this.post.permissions.find((value) => value?.toLowerCase() === 'blogpost.edit');

    this.metaService.setMeta({
      title: this.post.title,
      description: this.post.seoDescription,
      url: this.stateService.href('main.blog', { id: this.post.id }),
      type: 'website',
    });
  }

  goBack(): void {
    this.backUrlService.goBack();
  }

  get editPath(): string {
    return this.post.moduleId
      ? ['/gateway/blog/', this.post.id, '/edit', '/?', this.backUrlService.external.getBackUrlParam()].join('')
      : this.stateService.href('edit.blogPost', { postId: this.post.id });
  }
}
