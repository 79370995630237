<div>
  <div
    class="content row player-no-content text-center"
    *ngIf="noInteractiveContent"
    [ngClass]="{ 'has-up-next-button': wrapped }">
    <!--page 4-->
    <fieldset title="Course title" class="text-left">
      <legend class="text-left">
        {{ course.name }}
      </legend>
    </fieldset>
    <div>
      <!--      <p>This {{(course.label.name||course.formatName)}}
        requires its own window. Please visit the <a href="#"
        title="Open Course Details" ui-sref="main.course({id: course.id})">course details page</a> to access it.</p>-->
      <button
        type="button"
        class="btn btn-primary btn-lg"
        uiSref="main.course"
        [uiParams]="{ id: course.id, currentBlsId: course.current_bls_id }"
        [id]="trackingName + '_component_' + course.id + '_details_link'"
        title="Open">
        Open
      </button>
    </div>
  </div>

  <div *ngIf="player && !noInteractiveContent">
    <div *ngIf="!error && player.currentStep" class="player-current-step">
      <!-- QUIZ-->
      <div class="content row player-quiz" *ngIf="isQuizStep()">
        <div *ngIf="!player.currentType">
          <div class="load-spinner"></div>
        </div>

        <course-quiz-player
          [type]="player.currentType"
          [workflow]="workflow"
          [quizRetake]="quizRetakeSubject"
          [playerClose]="closeSubject"
          (retake)="retakeResult($event)"
          (finish)="stepFinished($event)">
        </course-quiz-player>
      </div>

      <!-- Content-->
      <course-content-view
        [workflow]="workflow"
        *ngIf="player.currentStep.contentStep"
        [wrapped]="wrapped"
        [type]="player.currentType"
        (finished)="stepFinished()"
        (closed)="closePlayer()"
        (launch)="launchContent($event)"
        [quizRetake]="quizRetakeSubject"
        (retake)="retakeResult($event)"
        [closeSubject]="closeSubject"
        [playerActions]="playerActions">
      </course-content-view>

      <div class="text-center" *ngIf="continueIsAvailable()">
        <button
          type="button"
          class="btn btn-primary btn-lg"
          [id]="trackingName + '_component_' + course.id + '_next_step'"
          (click)="moveToTheNextStep()">
          Continue
        </button>
      </div>
    </div>

    <div class="content row player-current-step" *ngIf="error">
      <div class="alert alert-sm alert-danger">{{ error }}</div>
    </div>
  </div>
</div>
