import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'incomplete-confirmation-component',
  templateUrl: './incomplete-confirmation.component.html',
})
export class IncompleteConfirmationComponent {
  trackingName = 'course_player_incomplete_warning';
  constructor(private activeModal: NgbActiveModal) {}

  cancel(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    this.activeModal.close('ok');
  }
}
