import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { ISearchFilterTypes } from 'app/deprecated/search/models/search-filters.models';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import moment from 'moment';
import { Unsubscribable, finalize, tap } from 'rxjs';
import { ICalendarEvent, ICalendarQueryParams, ICalendarView, ICalendarViewMode } from '../models/events.model';
import { CalendarEventsService } from '../services/calendar-events.service';

@Component({
  selector: 'calendar-events',
  templateUrl: './calendar-events.component.html',
})
export class CalendarEventsComponent implements OnInit, OnDestroy {
  @Input() showRegistrationStatus: boolean;

  mode: 'search' | 'calendar';
  calendarMode: 'default' | 'list' | string;

  minDate: string;
  maxDate: string;
  registeredOnly: boolean;
  calendarView: ICalendarViewMode;

  query: string;
  filtersCount: number;
  toggleModeName: string;

  items: ICalendarEvent[];
  searching: boolean;
  itemsCount: number;

  groupIds?: string[];
  sessionLabels?: string;
  filters: ISearchFilterTypes;

  private urlParams: IUrlParams;
  private requestSubscriber?: Unsubscribable;
  private urlHandlerDestroy: UrlParamHandler;
  private viewsMap: Record<string, ICalendarViewMode> = {
    month: 'dayGridMonth',
    agendaWeek: 'timeGridWeek',
    agendaDay: 'timeGridDay',
    list: 'listWeek',
  };

  private reverceViewsMap: Record<ICalendarViewMode, string> = {
    dayGridMonth: 'month',
    timeGridWeek: 'agendaWeek',
    timeGridDay: 'agendaDay',
    listWeek: 'list',
  };

  private readonly defaultParams = { calendarView: 'dayGridMonth', calendarMode: 'default' };

  constructor(
    private eventsService: CalendarEventsService,
    private activeState: UIRouterGlobals,
    private stateService: StateService,
    private urlService: UrlService,
  ) {}

  ngOnInit(): void {
    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler>this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy() {
    this.urlHandlerDestroy();

    if (this.requestSubscriber) {
      this.requestSubscriber.unsubscribe();
      delete this.requestSubscriber;
    }
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.ngOnUrlParamsChange(params);
      this.urlParams = params;
    }
  }

  ngOnUrlParamsChange(params: IUrlParams<string>) {
    this.query = params.query;
    this.mode = params.mode ? 'search' : 'calendar';

    this.minDate = params.min_start_date;
    this.maxDate = params.max_start_date;
    this.groupIds = params.group_id?.split(',');
    this.sessionLabels = params.session_label_id;
    this.registeredOnly = !!params.user_courses;

    this.calendarMode = params.calendarViewMode || this.defaultParams.calendarMode;
    this.toggleModeName = this.calendarMode === 'default' ? 'Show list' : 'Show calendar';
    this.calendarView = this.viewsMap[params.view] || (this.defaultParams.calendarView as ICalendarViewMode);

    if (this.mode === 'search') {
      this.search();
    }
  }

  showCalendar() {
    const filters = Object.assign({}, this.urlParams, {
      query: null,
      mode: null,
      calendarViewMode: this.defaultParams.calendarMode,
    });

    this.items = null;
    this.itemsCount = null;
    this.stateService.go(this.activeState.current, filters);
  }

  onSubmit() {
    if (this.query !== this.urlParams.query || this.mode !== 'search') {
      const filters = Object.assign({}, this.urlParams, {
        query: this.query,
        mode: 'search',
        calendarViewMode: 'list',
      });

      this.stateService.go(this.activeState.current, filters);
    } else {
      this.search();
    }
  }

  search() {
    const query: ICalendarQueryParams = {
      query: this.query,
      group_id: this.groupIds?.join(','),
      user_courses: this.registeredOnly || null,
      session_label_id: this.sessionLabels,
      min_start_date: moment().format('YYYY-MM-DDZ'), // Get only future events
      max_start_date: null,
    };

    this.searching = true;
    this.requestSubscriber?.unsubscribe();
    this.requestSubscriber = this.eventsService
      .query(query, true)
      .pipe(
        tap((data) => {
          this.itemsCount = data.count;
          this.items = data.items;
        }),
        finalize(() => {
          this.searching = false;
          this.requestSubscriber?.unsubscribe();
          delete this.requestSubscriber;
        }),
      )
      .subscribe();
  }

  loadMore() {
    if (this.itemsCount > this.items.length && !this.processing) {
      this.requestSubscriber?.unsubscribe();
      this.requestSubscriber = this.eventsService
        .next(true)
        .pipe(
          tap((items) => this.items.push(...items)),
          finalize(() => {
            this.requestSubscriber?.unsubscribe();
            delete this.requestSubscriber;
          }),
        )
        .subscribe();
    }
  }

  toggleCalendarMode() {
    const mode = this.calendarMode === 'default' ? 'list' : 'default';
    const filters = Object.assign({}, this.urlParams, {
      calendarViewMode: mode !== this.defaultParams.calendarMode ? mode : null,
      query: null,
      mode: null,
    });

    this.items = null;
    this.itemsCount = null;
    this.stateService.go(this.activeState.current, filters);
  }

  onCalendarViewChange(info: ICalendarView) {
    const filters = Object.assign({}, this.urlParams, {
      view: info.view !== this.defaultParams.calendarView ? this.reverceViewsMap[info.view] : null,
      min_start_date: info.minDate,
      max_start_date: info.maxDate,
    });

    this.stateService.go(this.activeState.current, filters);
  }

  @Input()
  set searchFilters(value: ISearchFilterTypes | null) {
    this.filters = value;
    this.filtersCount = value ? this.countFilters() : 0;
  }

  get processing(): boolean {
    return !!this.requestSubscriber;
  }

  private countFilters(): number {
    return (this.filters?.ui.filter((i) => i.visible && i.items?.length) || []).length;
  }
}
