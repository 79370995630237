import { Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { IFileUploadOptions } from 'components/file-upload/file-upload.model';
import { LmsValueAccessor } from 'components/forms/value-accessor.component';
import { ElmsUtils } from 'core/utils';
import { IEntityUser } from 'modules/user/models/user.model';

@Component({
  selector: 'email-attachments',
  templateUrl: './email-attachments.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EmailAttachmentsComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailAttachmentsComponent), multi: true },
  ],
})
export class EmailAttachmentsComponent extends LmsValueAccessor<File[]> implements Validator {
  @Input() maxAttachmentsSize = 10;

  invalid = false;
  receiveEmails: boolean;
  taggedEmailValid = false;
  allRecipients: Partial<IEntityUser>[] = [];
  uploadOptions: IFileUploadOptions = {
    maxFileSizeMb: this.maxAttachmentsSize,
    acceptFileTypes: '*/*',
  };

  triggerFileSelection: () => void;

  openTriggerChange(openTrigger: () => void) {
    this.triggerFileSelection = openTrigger;
  }

  formatFileSize(bytes: number): string {
    return ElmsUtils.formatBytes(bytes);
  }

  onFileSelected(file: File) {
    if (file) {
      this.setValue(this.value.concat(file));
    }
  }

  removeAttachment(file: File) {
    this.setValue(this.value.filter((i) => i !== file));
  }

  validate(control: AbstractControl<File[]>): ValidationErrors | null {
    const attachmentsSize = control.value?.reduce((a, i) => a + i.size, 0);
    const maxSize = this.maxAttachmentsSize * 1e6;

    if (attachmentsSize > maxSize) {
      this.invalid = true;

      return { maxSize: { maxSize: this.formatFileSize(maxSize), actual: this.formatFileSize(attachmentsSize) } };
    }

    this.invalid = false;

    return null;
  }
}
