import { Component, Input } from '@angular/core';
import { IAccreditationAgency } from 'modules/accreditation-agencies/models/accreditation-agency.model';

@Component({
  selector: 'accreditation-agency-info',
  templateUrl: './accreditation-agency-info.component.html',
})
export class AccreditationAgencyInfoComponent {
  static readonly selector = 'accreditationAgencyInfo';

  @Input() agency: IAccreditationAgency;
  @Input() mode?: 'full' | 'short';
}
