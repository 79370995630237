import { Component, Input, OnInit } from '@angular/core';
import { ICoursePrice } from 'modules/course/common/models/course.model';
import { CourseService } from 'modules/course/common/services/course.service';
import { lastValueFrom, map, tap } from 'rxjs';

@Component({
  selector: 'course-price',
  templateUrl: './course-price.component.html',
})
export class CoursePriceComponent implements OnInit {
  static readonly selector = 'coursePrice';

  @Input() coursePrice: ICoursePrice;

  currencies: any = [];
  currentCurrency: any;
  formattedPrice: string;
  currencyCode: string;

  constructor(private courseService: CourseService) {}

  ngOnInit() {
    this.currencyCode = this.coursePrice.currency;

    this.loadCurrencies()
      .then((currencies) => {
        this.currencies = currencies;

        this.currentCurrency = currencies.find((c) => c.code === this.coursePrice.currency);
      })
      .finally(() => {
        this.formattedPrice = this.formatPrice(this.coursePrice.amount);
      });
  }

  formatPrice(value: number): string {
    if (value && !isNaN(value)) {
      return (Math.round(value * 100) / 100).toFixed(2);
    }

    return null;
  }

  loadCurrencies(): Promise<any[]> {
    const cachedCurrencies = JSON.parse(localStorage.getItem('currencies'));

    if (cachedCurrencies && cachedCurrencies.length > 0) {
      return Promise.resolve(cachedCurrencies);
    }

    return lastValueFrom(
      this.courseService.getCourseOptions().pipe(
        tap((courseOptions) => {
          localStorage.setItem('currencies', JSON.stringify(courseOptions.currencies));
        }),
        map((courseOptions) => courseOptions.currencies || []),
      ),
    );
  }
}
